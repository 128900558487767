import React, { useEffect, useState } from 'react';
import Button from '~/components/Button';
import CardOptionCell from '~/components/CardOptionCell';
import CommonSelectableCell from '~/components/CommonSelectableCell';
import InputField from '~/components/InputField';
import { SettingPanel, SettingToggleItem } from '~/components/SettingPanel';
import { CustomList, RankingBaseQuestion } from '~/data/models/yearbook';
import { AllowedVoteType, AnswerType } from '~/data/operations/global';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  ScrollView,
  InputContainer,
  SectionHeader,
  OptionsContainer,
  ToggleContainer,
  ButtonContainer,
  Content,
  Loading,
} from './style';

export interface RankingQuestionInput {
  question: string;
  answerType: AnswerType;
  allowedVotes?: AllowedVoteType;
  onlyOppositeGender?: boolean;
  customListId?: string;
}

export type RankingsAddQuestionLayoutProps = {
  initialRankingQuestion?: RankingBaseQuestion;
  loading?: boolean;
  saveLoading?: boolean;
  customLists: CustomList[];
  onBack: () => void;
  onAddQuestion: (rankingQuestionInput: RankingQuestionInput) => void;
  onSelectedList: (listId?: string, listName?: string) => void;
};

export default function RankingsAddQuestion({
  initialRankingQuestion,
  loading,
  saveLoading,
  customLists,
  onBack,
  onAddQuestion,
  onSelectedList,
}: RankingsAddQuestionLayoutProps): JSX.Element {
  const getInitialRankingQuestionInput = (): RankingQuestionInput => {
    return {
      question: initialRankingQuestion?.question || '',
      answerType: initialRankingQuestion?.answerType || AnswerType.PERSON,
      allowedVotes:
        initialRankingQuestion?.allowedVotes || AllowedVoteType.EVERYBODY,
      onlyOppositeGender: initialRankingQuestion?.onlyOppositeGender || false,
      customListId: initialRankingQuestion?.customList?.id,
    };
  };

  const [rankingQuestionInput, setRankingQuestionInput] =
    useState<RankingQuestionInput>(getInitialRankingQuestionInput);

  useEffect(() => {
    if (initialRankingQuestion) {
      const initialRankingQuestionInput = getInitialRankingQuestionInput();
      setRankingQuestionInput(initialRankingQuestionInput);
    }
  }, [initialRankingQuestion]);

  const isPersonVote = rankingQuestionInput.answerType === AnswerType.PERSON;

  const isOnlyOppositeGenderEnabled =
    isPersonVote &&
    (rankingQuestionInput.allowedVotes === AllowedVoteType.MALE ||
      rankingQuestionInput.allowedVotes === AllowedVoteType.FEMALE);

  const isListVote = rankingQuestionInput.answerType === AnswerType.LIST;

  const hasQuestionText = rankingQuestionInput.question.length > 0;

  const isButtonEnabled = isListVote
    ? hasQuestionText && !!rankingQuestionInput.customListId
    : hasQuestionText;

  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.rankingAdd')}
      onBackPress={onBack}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <InputContainer>
          <InputField
            testID={'inputQuestion'}
            value={rankingQuestionInput.question}
            label={t('rankings.addQuestionHint')}
            onChangeText={(question) =>
              setRankingQuestionInput({
                ...rankingQuestionInput,
                question,
              })
            }
          />
        </InputContainer>
        {(() => {
          if (loading) {
            return (
              <Content>
                <Loading />
              </Content>
            );
          }
          return (
            <>
              <SectionHeader
                title={t('rankings.addQuestionSettingsSubheader')}
                sub
              />
              <OptionsContainer>
                <CardOptionCell
                  testID={'cardOptionPerson'}
                  text={t('rankings.addQuestionSettingsSolo')}
                  iconStyledName={'person'}
                  iconSize={24}
                  isSelected={
                    rankingQuestionInput.answerType === AnswerType.PERSON
                  }
                  onPress={() =>
                    setRankingQuestionInput({
                      ...rankingQuestionInput,
                      answerType: AnswerType.PERSON,
                    })
                  }
                />
                <CardOptionCell
                  testID={'cardOptionCouple'}
                  text={t('rankings.addQuestionSettingsCouple')}
                  iconStyledName={'couple'}
                  isSelected={
                    rankingQuestionInput.answerType === AnswerType.COUPLE
                  }
                  onPress={() =>
                    setRankingQuestionInput({
                      ...rankingQuestionInput,
                      answerType: AnswerType.COUPLE,
                    })
                  }
                />
                <CardOptionCell
                  testID={'cardOptionList'}
                  text={t('rankings.addQuestionSettingsList')}
                  iconStyledName={'list'}
                  isSelected={
                    rankingQuestionInput.answerType === AnswerType.LIST
                  }
                  onPress={() =>
                    setRankingQuestionInput({
                      ...rankingQuestionInput,
                      answerType: AnswerType.LIST,
                    })
                  }
                />
              </OptionsContainer>
              {isPersonVote ? (
                <>
                  <SectionHeader
                    title={t('rankings.addQuestionGroupsSubheader')}
                    sub
                  />
                  <OptionsContainer>
                    <CardOptionCell
                      text={t('rankings.addQuestionGroupsAll')}
                      isSelected={
                        rankingQuestionInput.allowedVotes ===
                        AllowedVoteType.EVERYBODY
                      }
                      onPress={() =>
                        setRankingQuestionInput({
                          ...rankingQuestionInput,
                          allowedVotes: AllowedVoteType.EVERYBODY,
                        })
                      }
                    />
                    <CardOptionCell
                      text={t('rankings.addQuestionGroupsMale')}
                      isSelected={
                        rankingQuestionInput.allowedVotes ===
                        AllowedVoteType.MALE
                      }
                      onPress={() =>
                        setRankingQuestionInput({
                          ...rankingQuestionInput,
                          allowedVotes: AllowedVoteType.MALE,
                        })
                      }
                    />
                    <CardOptionCell
                      text={t('rankings.addQuestionGroupsFemale')}
                      isSelected={
                        rankingQuestionInput.allowedVotes ===
                        AllowedVoteType.FEMALE
                      }
                      onPress={() =>
                        setRankingQuestionInput({
                          ...rankingQuestionInput,
                          allowedVotes: AllowedVoteType.FEMALE,
                        })
                      }
                    />
                    <CardOptionCell
                      text={t('rankings.addQuestionGroupsDivers')}
                      isSelected={
                        rankingQuestionInput.allowedVotes ===
                        AllowedVoteType.OTHER
                      }
                      onPress={() =>
                        setRankingQuestionInput({
                          ...rankingQuestionInput,
                          allowedVotes: AllowedVoteType.OTHER,
                        })
                      }
                    />
                  </OptionsContainer>
                </>
              ) : null}
              {isListVote ? (
                <>
                  <SectionHeader
                    title={t('rankings.addQuestionListSubheader')}
                    sub
                    buttonTestID={'addListButton'}
                    buttonIcon={'plus'}
                    buttonText={t('rankings.addQuestionListAdd')}
                    onButtonPress={() => onSelectedList()}
                  />
                  {customLists.map((customList) => (
                    <CommonSelectableCell
                      testID={`ListCell:${customList.id}`}
                      key={customList.id}
                      text={customList.title || ''}
                      selected={
                        customList.id === rankingQuestionInput.customListId
                      }
                      onPress={() =>
                        onSelectedList(
                          customList.id,
                          customList.title || undefined,
                        )
                      }
                      onChange={(selected) =>
                        setRankingQuestionInput({
                          ...rankingQuestionInput,
                          customListId: selected ? customList.id : undefined,
                        })
                      }
                    />
                  ))}
                </>
              ) : null}
              {isOnlyOppositeGenderEnabled ? (
                <>
                  <ToggleContainer>
                    <SettingPanel>
                      <SettingToggleItem
                        text={
                          rankingQuestionInput.allowedVotes ===
                          AllowedVoteType.MALE
                            ? t('rankings.addQuestionOnlyFemale')
                            : t('rankings.addQuestionOnlyMale')
                        }
                        checked={!!rankingQuestionInput.onlyOppositeGender}
                        onToggleChange={() =>
                          setRankingQuestionInput({
                            ...rankingQuestionInput,
                            onlyOppositeGender:
                              !rankingQuestionInput.onlyOppositeGender,
                          })
                        }
                      />
                    </SettingPanel>
                  </ToggleContainer>
                </>
              ) : null}
            </>
          );
        })()}
      </ScrollView>
      <ButtonContainer>
        <Button
          testID={'addQuestionButton'}
          state={isButtonEnabled ? 'default' : 'disabled'}
          loading={saveLoading}
          text={t('rankings.addQuestionSubmit')}
          flex
          onPress={() =>
            onAddQuestion({
              ...rankingQuestionInput,
              allowedVotes: isPersonVote
                ? rankingQuestionInput.allowedVotes
                : undefined,
              onlyOppositeGender: isOnlyOppositeGenderEnabled
                ? rankingQuestionInput.onlyOppositeGender
                : undefined,
            })
          }
        />
      </ButtonContainer>
    </ModalScreenLayout>
  );
}
