import React, { useState } from 'react';
import { Image } from 'react-native';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import MemberCell from '~/components/MemberCell';
import { NotificationTeamRequestCarousel } from '~/components/NotificationCarousel';
import PostCell, { PostCellActionProps } from '~/components/PostCell';
import TeamTypeBanner from '~/components/TeamTypeBanner';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { AdsImage, AdsText } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { PollOption, Post } from '~/data/models/post';
import { Team, TeamUser } from '~/data/models/team';
import { Status, VariantTypeEnum } from '~/data/operations/global';
import { getPollVotedOptions } from '~/data/utils';
import { TabScreenLayout } from '~/screens/style';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import Header from './Header';
import {
  HeaderBG,
  HeaderContainer,
  MemberCellView,
  Content,
  Loading,
  EmptyText,
  RefreshControl,
  HiddenTitle,
  HiddenDescp,
  TeamBannerView,
  PendingText,
  BannersContainer,
  PostWrapper,
  EmptyActivityText,
  NotificationCarouselContainer,
} from './style';

export type TeamDetailLayoutProps = {
  authUserId: string;
  loggedTeamUser?: TeamUser;
  team?: Team;
  loading: boolean;
  initialTabItemKey?: 'activity' | 'members';
  posts: (BestMatchedAsset | Post)[];
  teamUsers: TeamUser[];
  pendingTeamUsers: TeamUser[];
  adminUserIds: string[];
  authTeamIds: string[];
  activateTeamLoading?: boolean;
  now?: Date;
  postsHasNextPage?: boolean;
  onBack: () => void;
  onSetting: () => void;
  onChat: () => void;
  onNotification: () => void;
  onCreatePost: () => void;
  onCreatePoll: () => void;
  onRequestAccess: () => void;
  onLoadMorePosts: () => void;
  onRefresh: () => void;
  onDeclineUser: (userId: string) => void;
  onAcceptUser: (userId: string) => void;
  onActivateTeam: () => void;
  openBannersInBrowser: (url: string) => void;
} & PostCellActionProps;

export default function TeamDetail({
  authUserId,
  loggedTeamUser,
  team,
  loading,
  initialTabItemKey = 'activity',
  posts,
  teamUsers,
  pendingTeamUsers,
  adminUserIds,
  authTeamIds,
  activateTeamLoading,
  now = new Date(),
  postsHasNextPage,
  onBack,
  onSetting,
  onCreatePost,
  onCreatePoll,
  onRequestAccess,
  onLike,
  onDelete,
  onReportPost,
  onReportUser,
  onBlockUser,
  onHidePost,
  onComment,
  onLoadMorePosts,
  onRefresh,
  onPollVote,
  onDeclineUser,
  onAcceptUser,
  onActivateTeam,
  onEdit,
  openBannersInBrowser,
}: TeamDetailLayoutProps): JSX.Element {
  const teamColor = team?.isHidden ? undefined : team?.color?.hex;
  const iconName = team?.icon?.icon;
  const teamMember = loggedTeamUser?.status === Status.ACCEPTED;
  const pendingRequest = loggedTeamUser?.status === Status.PENDING;

  const [selectedTabItemKey, setSelectedTabItemKey] =
    useState<string>(initialTabItemKey);

  const { handleViewTracking, handleAdsClick } = useAdsTracking({
    authUserId,
    variantType: VariantTypeEnum.SMALL,
  });

  const data: (Post | TeamUser | BestMatchedAsset)[] = team?.isHidden
    ? []
    : selectedTabItemKey === 'activity'
    ? posts
    : teamUsers;

  const renderItem = ({
    item,
    index,
  }: {
    item?: Post | TeamUser | BestMatchedAsset;
    index: number;
  }) => {
    if (selectedTabItemKey === 'activity' && item?.__typename === 'PostNode') {
      const post = item as Post;
      const pollVotedOptions = getPollVotedOptions(
        (post?.poll?.options as PollOption[]) || [],
        authUserId as string,
      );
      return (
        <PostWrapper>
          <PostCell
            testID={`${index}`}
            now={now}
            post={post}
            isPostOwner={authUserId === post.createdBy?.user.id}
            pollVotedOptions={pollVotedOptions}
            authTeamIds={authTeamIds}
            onPollVote={onPollVote}
            onLike={onLike}
            onComment={onComment}
            onDelete={onDelete}
            onReportPost={onReportPost}
            onReportUser={onReportUser}
            onBlockUser={onBlockUser}
            onHidePost={onHidePost}
            onEdit={onEdit}
          />
        </PostWrapper>
      );
    }
    if (item?.__typename === 'MatchedAssetNode') {
      const post = item as BestMatchedAsset;
      return (
        <BannersContainer
          testID={`${index}:AdsCell:${post?.id}`}
          onPress={() => {
            handleAdsClick(
              post?.id as string,
              post?.channelData?.[0]?.trackingUrl as string,
              openBannersInBrowser,
            );
          }}
        >
          <AdsText>{t('asset.gratitude')}</AdsText>
          <AdsImage
            source={{ uri: post?.channelData?.[0]?.imageSmall as string }}
          />
        </BannersContainer>
      );
    }

    const teamUser = item as TeamUser;
    return (
      <MemberCellView>
        <MemberCell
          teamUser={teamUser}
          teamColor={teamColor}
          admin={adminUserIds.includes(teamUser?.user.id)}
        />
      </MemberCellView>
    );
  };

  return (
    <TabScreenLayout
      bgColorKey="c1"
      backButtonTestID={'buttonBack'}
      colorStyle={'light'}
      onBackPress={onBack}
      RightComponent={
        <>
          {!team?.isHidden && (
            <>
              {teamMember ? (
                <Button
                  testID={'buttonIconSetting'}
                  text={t('g.settings')}
                  minWidth={130}
                  size={'sm'}
                  onPress={onSetting}
                  type={'secondary-base'}
                />
              ) : pendingRequest ? (
                <PendingText>{t('g.pending')}</PendingText>
              ) : (
                <Button
                  testID={'buttonJoin'}
                  text={t('g.requestAccess')}
                  minWidth={130}
                  size={'sm'}
                  onPress={onRequestAccess}
                  type={'secondary-base'}
                />
              )}
            </>
          )}
        </>
      }
      BgComponent={<HeaderBG teamColor={teamColor} />}
    >
      <TrackingFlatlist
        testID={'flatList'}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        ListHeaderComponent={
          <HeaderContainer teamColor={teamColor}>
            <Header
              selectedTabItemKey={selectedTabItemKey}
              teamColor={teamColor}
              iconName={iconName}
              pendingRequest={pendingRequest}
              teamMember={!!teamMember}
              isHidden={!team || team?.isHidden}
              teamName={team?.name}
              teamBadgeName={team?.type}
              onTabItemPress={(tabItem) => setSelectedTabItemKey(tabItem)}
              onCreatePostPress={onCreatePost}
              onCreatePollPress={onCreatePoll}
              onRequestAccessPress={onRequestAccess}
            />
            {selectedTabItemKey === 'members' &&
            !!teamMember &&
            team &&
            !team.isHidden &&
            pendingTeamUsers.length > 0 ? (
              <NotificationCarouselContainer>
                <NotificationTeamRequestCarousel
                  teamUsers={pendingTeamUsers}
                  team={team}
                  now={now}
                  onDeclineUser={onDeclineUser}
                  onAcceptUser={onAcceptUser}
                />
              </NotificationCarouselContainer>
            ) : null}
          </HeaderContainer>
        }
        ListFooterComponent={() => {
          if (team?.isHidden) {
            return null;
          }

          if (loading) {
            return (
              <Content>
                <Loading />
              </Content>
            );
          }
          if (
            loggedTeamUser &&
            loggedTeamUser.status === Status.ACCEPTED &&
            selectedTabItemKey === 'activity' &&
            !postsHasNextPage &&
            data.length === 0
          ) {
            return (
              <Content>
                <Image source={general.megaphone_sticker} />
                <EmptyActivityText>
                  {t('teamDetail.noPostsYet')}
                </EmptyActivityText>
              </Content>
            );
          }
          return <Content />;
        }}
        ListEmptyComponent={() => {
          if (team?.isHidden) {
            return (
              <Content>
                <HiddenTitle>{t('teamDetail.hiddenTitle')}</HiddenTitle>
                <HiddenDescp>{t('teamDetail.hiddenDescp')}</HiddenDescp>
                {team?.type ? (
                  <TeamBannerView>
                    <TeamTypeBanner teamType={team.type} />
                  </TeamBannerView>
                ) : null}
                <Button
                  testID={'buttonActivateTeam'}
                  text={t('teamDetail.activateTeam')}
                  loading={activateTeamLoading}
                  flex
                  onPress={onActivateTeam}
                />
              </Content>
            );
          }

          if (
            !loading &&
            !!authUserId &&
            !teamMember &&
            selectedTabItemKey === 'activity'
          ) {
            return (
              <Content>
                <EmptyText>{t('teamDetail.activityEmpty')}</EmptyText>
              </Content>
            );
          }
          return null;
        }}
        data={data}
        renderItem={renderItem}
        handleViewTracking={handleViewTracking}
        onEndReachedThreshold={0.3}
        onEndReached={() =>
          selectedTabItemKey === 'activity' &&
          postsHasNextPage &&
          onLoadMorePosts()
        }
      />
    </TabScreenLayout>
  );
}
