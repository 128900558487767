import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReactNativeFile } from 'apollo-upload-client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { GroupIcon } from '~/data/models/custom';
import { GraduationType, Group } from '~/data/models/group';
import { useGroupIconsQuery } from '~/data/operations/custom/groupIcons';
import { useSetGroupAvatarMutation } from '~/data/operations/group/setGroupAvatar';
import { useUpdateGroupMutation } from '~/data/operations/group/updateGroup';
import { ProfileStackParamList } from '~/navigation/types';
import { getFileName } from '~/utils/helpers';
import { EditGroupInput } from '../GroupSetup/layout';
import GroupSettingsLayout from './layout';

type GroupSettingsNavProp = StackNavigationProp<
  ProfileStackParamList,
  'GroupSettings'
>;

type GroupSettingsRouteProp = RouteProp<ProfileStackParamList, 'GroupSettings'>;

export default function GroupSettings(): JSX.Element {
  const navigation = useNavigation<GroupSettingsNavProp>();
  const {
    params: { group },
  } = useRoute<GroupSettingsRouteProp>();
  const [updateGroup] = useUpdateGroupMutation();
  const groupData = group.group as Group;
  const { authGroupId } = useAuth();
  const graduationTypes =
    (group.group?.institute?.type.graduationTypes as GraduationType[]) || [];

  const [setGroupAvatar, { loading: setGroupAvatarLoading }] =
    useSetGroupAvatarMutation();
  const { data: groupIconsData } = useGroupIconsQuery({
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const groupIcons: GroupIcon[] =
    (groupIconsData?.groupIcons?.edges.map(
      (edge) => edge?.node,
    ) as GroupIcon[]) || [];
  const onUpdateGroup = async ({ name, typeId, year }: EditGroupInput) => {
    try {
      const { data } = await updateGroup({
        variables: {
          input: {
            id: authGroupId,
            name,
            type: typeId,
            year: parseInt(year),
          },
        },
      });
      const messages = data?.updateGroup?.errors?.map(
        (error) => error?.messages[0],
      );
      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateAvatar = async (uri: string | null, iconId: string | null) => {
    try {
      const imageFile = uri
        ? new ReactNativeFile({
            uri,
            type: 'image/jpeg',
            name: getFileName(uri),
          })
        : null;

      await setGroupAvatar({
        variables: {
          input: {
            id: authGroupId,
            avatar: imageFile,
            iconId,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return (
    <GroupSettingsLayout
      onBack={() => navigation.goBack()}
      group={groupData}
      graduationTypes={graduationTypes}
      setGroupAvatarLoading={setGroupAvatarLoading}
      onUpdateGroup={onUpdateGroup}
      onUpdateAvatar={onUpdateAvatar}
      groupIcons={groupIcons}
    />
  );
}
