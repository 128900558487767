import React, { useState } from 'react';
import { FlatList } from 'react-native';
import { RefreshControl } from 'react-native-gesture-handler';
import CommonIconCell from '~/components/CommonIconCell';
import InputField from '~/components/InputField';
import { SectionLabel, Spacer, VSpacer } from '~/components/common/style.web';
import { CustomPageFields } from '~/data/operations/yearbook/types/CustomPageFields';
import { TabScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { Header } from '../../Custom/layout/style';

type CustomPageResultsLayoutProps = {
  onBack: () => void;
  loading: boolean;
  hasNextPage?: boolean;
  userCustomPages?: CustomPageFields[];
  onRefresh: () => void;
  onLoadMore: () => void;
  onOpenCustomPage: (customPageId: string) => void;
};

export default function CustomPageResultsLayout({
  onBack,
  loading,
  hasNextPage,
  userCustomPages,
  onRefresh,
  onLoadMore,
  onOpenCustomPage,
}: CustomPageResultsLayoutProps): JSX.Element {
  const [searchingText, setSearchingText] = useState('');
  const renderItem = ({ item }: { item: CustomPageFields }) => {
    return (
      <CommonIconCell
        testID={'buttonCellPoll'}
        info={{ text: item.title }}
        onPress={() => onOpenCustomPage(item.id)}
      />
    );
  };

  const filteredUsersSummary = userCustomPages?.filter(
    (userCustomPage) =>
      userCustomPage.title
        .toLocaleLowerCase()
        .includes(searchingText.toLocaleLowerCase()) ||
      userCustomPage.title
        .toLocaleLowerCase()
        .includes(searchingText.toLocaleLowerCase()),
  );
  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.customPageResults')}
      onBackPress={onBack}
    >
      <Header>
        <Spacer v={16} />
        <FlatList
          data={filteredUsersSummary}
          renderItem={renderItem}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={onRefresh} />
          }
          keyExtractor={(item) => item.id}
          ItemSeparatorComponent={() => <Spacer v={8} />}
          onEndReached={() => hasNextPage && onLoadMore()}
          ListHeaderComponent={
            <>
              <SectionLabel>
                {t('customPages.allCustomPagesResult')}
              </SectionLabel>
              <Spacer v={16} />
              <InputField
                testID={'inputSearch'}
                iconName={'search-md'}
                label={t('g.search')}
                value={searchingText}
                onChangeText={(value) => {
                  setSearchingText(value);
                }}
              />
              <VSpacer />
            </>
          }
          ListFooterComponent={<Spacer v={50} />}
        />
      </Header>
    </TabScreenLayout>
  );
}
