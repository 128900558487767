import React, { useEffect, useState } from 'react';
import InputField from '~/components/InputField';
import MemberCell from '~/components/MemberCell';
import { UserGroup } from '~/data/models/group';
import { User } from '~/data/models/user';
import { CustomListOption, RankingBaseQuestion } from '~/data/models/yearbook';
import { AnswerType } from '~/data/operations/global';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { Title, InputView, UserList, Footer, Loading } from './style';

export type UserVote = {
  userGroupId1?: string;
  userGroupId2?: string;
  listOptionId?: string;
};

export type RankingVoteLayoutProps = {
  rankingQuestion?: RankingBaseQuestion | null;
  userGroups: UserGroup[];
  customListOptions: CustomListOption[];
  loading: boolean;
  onBack: () => void;
  onUserVote: (userVote: UserVote) => void;
  onLoadMoreUsers: () => void;
};

export default function RankingVote({
  rankingQuestion,
  userGroups,
  customListOptions,
  loading,
  onBack,
  onUserVote,
  onLoadMoreUsers,
}: RankingVoteLayoutProps): JSX.Element {
  const [userGroupId1, setUserGroupId1] = useState<string | undefined>();
  const [userGroupId2, setUserGroupId2] = useState<string | undefined>();
  const [listOptionId, setListOptionId] = useState<string | undefined>();

  const [searchingText, setSearchingText] = useState('');

  const filteredItems =
    rankingQuestion?.answerType === AnswerType.LIST
      ? customListOptions.filter((customListOption) =>
          customListOption.text?.includes(searchingText),
        )
      : userGroups.filter(
          (userGroup) =>
            userGroup.user?.firstName.includes(searchingText) ||
            userGroup.user.lastName?.includes(searchingText),
        );

  useEffect(() => {
    if (rankingQuestion?.answerType === AnswerType.PERSON && userGroupId1) {
      onUserVote({
        userGroupId1,
      });
    } else if (
      rankingQuestion?.answerType === AnswerType.COUPLE &&
      userGroupId1 &&
      userGroupId2
    ) {
      onUserVote({
        userGroupId1,
        userGroupId2,
      });
    } else if (
      rankingQuestion?.answerType === AnswerType.LIST &&
      listOptionId
    ) {
      onUserVote({
        listOptionId,
      });
    }
  }, [userGroupId1, userGroupId2, listOptionId]);

  const renderItem = ({ item }: { item: UserGroup | CustomListOption }) => {
    if (item.__typename === 'UserGroupNode') {
      const userGroup = item;
      const isVoted =
        userGroup.id === userGroupId1 || userGroup.id === userGroupId2;
      return (
        <MemberCell
          testID={`MemberCell:${userGroup.id}`}
          userGroup={userGroup}
          customIcon={isVoted ? 'remove' : 'add'}
          onCustomIconPress={() =>
            userGroupId1 && rankingQuestion?.answerType === AnswerType.COUPLE
              ? setUserGroupId2(userGroup.id)
              : setUserGroupId1(userGroup.id)
          }
        />
      );
    } else {
      const customListOption = item;
      const isVoted = customListOption.id === userGroupId1;
      return (
        <MemberCell
          testID={`MemberCell:${customListOption.id}`}
          customInfo={{
            id: customListOption.id,
            text: customListOption.text as string,
          }}
          customIcon={isVoted ? 'remove' : 'add'}
          onCustomIconPress={() => setListOptionId(customListOption.id)}
        />
      );
    }
  };

  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.rankingsVote')}
      onBackPress={onBack}
    >
      <Title>{rankingQuestion?.question}</Title>
      <InputView>
        <InputField
          testID={'inputSearch'}
          iconName={'search-md'}
          label={t('g.search')}
          value={searchingText}
          onChangeText={(value) => {
            setSearchingText(value);
          }}
        />
      </InputView>
      <UserList<React.ElementType>
        testID={'userList'}
        data={filteredItems}
        renderItem={renderItem}
        keyExtractor={(item: User) => item.id}
        ListFooterComponent={<Footer>{loading ? <Loading /> : null}</Footer>}
        onEndReachedThreshold={0.3}
        onEndReached={() => onLoadMoreUsers()}
      />
    </ModalScreenLayout>
  );
}
