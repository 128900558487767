import React, { useEffect, useState } from 'react';
import CustomIconPicker from '~/components/CustomIconPicker';
import InputField from '~/components/InputField';
import SchoolTypePicker from '~/components/SchoolTypePicker';
import YearSelector from '~/components/YearSelector';
import { VSpacer } from '~/components/common/style.web';
import { Spacer } from '~/components/common/style.web';
import { GroupIcon } from '~/data/models/custom';
import { GraduationType, Group } from '~/data/models/group';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { EditGroupInput } from '../../GroupSetup/layout';
import { ShadowContainer } from '../../GroupSetup/layout/style';
import {
  ScrollView,
  InstituteInfoContainer,
  InstituteInfoTextContainer,
  InstituteInfoName,
  InstituteInfoParagraph,
  SectionLabel,
} from './style';

type GroupSettingsLayoutProps = {
  onBack: () => void;
  group: Group;
  graduationTypes: GraduationType[];
  groupIcons: GroupIcon[];
  setGroupAvatarLoading?: boolean;
  onUpdateGroup: (groupInput: EditGroupInput) => void;
  onUpdateAvatar: (uri: string | null, iconId: string | null) => void;
};

export default function GroupSettings({
  onBack,
  group,
  graduationTypes,
  groupIcons,
  setGroupAvatarLoading,
  onUpdateGroup,
  onUpdateAvatar,
}: GroupSettingsLayoutProps): JSX.Element {
  const [name, setName] = useState<string>(group.name);
  const [yearKey, setYearKey] = useState<number>(group.year);

  const [graduationType, setGraduationType] = useState<
    GraduationType | undefined
  >(group.type);

  function handleUpdateGroup() {
    const updatedName = name && name !== group.name;
    const updatedInstituteType =
      graduationType && graduationType.id !== group.type.id;
    const updatedYear = yearKey && yearKey !== group.year;

    if (
      graduationType &&
      (updatedName || updatedInstituteType || updatedYear)
    ) {
      onUpdateGroup({
        name,
        typeId: graduationType.id,
        year: yearKey.toString(),
      });
    }
  }

  useEffect(() => {
    handleUpdateGroup();
  }, [graduationType, yearKey]);

  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.groupSettings')}
      onBackPress={onBack}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <CustomIconPicker
          testID={'pickerImage'}
          modalTitle={t('g.setGroupImage')}
          customIcons={groupIcons.map((groupIcon) => ({
            id: groupIcon.id,
            uri: groupIcon.icon,
          }))}
          selectedIcon={
            group?.icon ? { id: group.icon.id, uri: group.icon.icon } : null
          }
          selectedUri={!group?.icon ? group?.avatar : null}
          loading={setGroupAvatarLoading}
          onSelect={({ imgUrl, customIcon }) =>
            onUpdateAvatar(imgUrl, customIcon?.id || null)
          }
        />
        <InputField
          testID={'inputName'}
          iconName={'flag-01'}
          label={t('createGroup.nameLabel')}
          placeholder={t('createGroup.namePlaceholder')}
          value={name}
          onChangeText={setName}
          blurOnSubmit={false}
          returnKeyType={'done'}
          onSubmitEditing={handleUpdateGroup}
        />
        <VSpacer />
        <SchoolTypePicker
          testID={'pickerSchool'}
          toggleTestID={'buttonPickerToggle'}
          label={t('graduationType')}
          schoolTypes={graduationTypes}
          selectedSchoolType={graduationType}
          includeNoSelection={false}
          onSelectedSchoolType={(schoolType) =>
            setGraduationType(schoolType as GraduationType)
          }
        />
        <VSpacer />
        <YearSelector initialYear={group.year} setYear={setYearKey} />
        <VSpacer />
        <SectionLabel>{t('groupSetup.instituteInfo')}</SectionLabel>
        <Spacer v={16} />
        <ShadowContainer>
          <InstituteInfoContainer>
            <InstituteInfoTextContainer>
              <InstituteInfoName>{group.institute.name}</InstituteInfoName>
              <InstituteInfoParagraph>
                {group.institute.street}
              </InstituteInfoParagraph>
              <InstituteInfoParagraph>
                {`${group.institute.zip} ${group.institute.city}`}
              </InstituteInfoParagraph>
            </InstituteInfoTextContainer>
          </InstituteInfoContainer>
        </ShadowContainer>
      </ScrollView>
    </ModalScreenLayout>
  );
}
