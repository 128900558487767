import React, { useRef } from 'react';
import Button from '~/components/Button';
import CommonIconCell, {
  CommonIconCellHandle,
} from '~/components/CommonIconCell';
import SectionHeader from '~/components/SectionHeader';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { AdsBiggerImage, VSpacer } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { ModuleInstance } from '~/data/models/yearbook';
import { VariantTypeEnum } from '~/data/operations/global';
import { TabScreenLayout } from '~/screens/style';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import { IconBox } from '../IconBox';
import { BoxWrapper, ScrollView, Section, AssetWrapper } from './style';

type ManageLayoutProps = {
  onBack: () => void;
  onExportData: () => void;
  onCollectedData: () => void;
  onBookStats: () => void;
  openBannersInBrowser: (url: string) => void;
  authUserId: string;
  moduleInstances: ModuleInstance[];
  onAddModule: () => void;
  onSelectModule: (moduleId: string) => void;
  onDeleteModuleInstance: (moduleInstanceId: string) => void;
};

export default function Manage({
  onBack,
  onExportData,
  onCollectedData,
  onBookStats,
  openBannersInBrowser,
  authUserId,
  onAddModule,
  onDeleteModuleInstance,
  onSelectModule,
  moduleInstances,
}: ManageLayoutProps): JSX.Element {
  const commonIconCellsRef = useRef<(CommonIconCellHandle | null)[]>([]);
  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.LARGE });

  const renderAdsItem = ({
    item,
    index,
  }: {
    item?: BestMatchedAsset;
    index: number;
  }) => {
    const post = item as BestMatchedAsset;

    return (
      <AssetWrapper
        testID={`${index}:AdsCell:${post?.id}`}
        onPress={() => {
          handleAdsClick(
            post?.id as string,
            post?.channelData?.[0]?.trackingUrl as string,
            openBannersInBrowser,
          );
        }}
      >
        <AdsBiggerImage
          source={{ uri: post?.channelData?.[0]?.imageLarge as string }}
        />
      </AssetWrapper>
    );
  };

  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.manageYearbook')}
      onBackPress={onBack}
      RightComponent={
        <Button
          testID={'buttonExportData'}
          text={t('manageYearbook.exportData')}
          size={'sm'}
          onPress={onExportData}
          type="primary-brand-02"
        />
      }
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <Section>
          <SectionHeader title={t('manageYearbook.overviewTitle')} />
          <VSpacer />
          <BoxWrapper>
            <IconBox
              testID={'buttonCollectedData'}
              text={t('manageYearbook.collectedData')}
              icon="file-search-02"
              onPress={onCollectedData}
            />
            <IconBox
              testID={'buttonBookStatistics'}
              text={t('manageYearbook.statistics')}
              icon="presentation-chart-02"
              onPress={onBookStats}
            />
          </BoxWrapper>
          <VSpacer />
          {bestMatchedAsset?.[randomIndex] !== undefined && (
            <TrackingFlatlist
              testID={'flatList'}
              handleViewTracking={handleViewTracking}
              data={[bestMatchedAsset[randomIndex]]}
              renderItem={renderAdsItem}
              scrollEnabled={false}
              ListFooterComponent={() => <></>}
            />
          )}

          <VSpacer height={20} />
          <SectionHeader
            title={t('manageYearbook.chapterTitle')}
            RightComponent={
              <Button
                testID={'buttonAddModule'}
                text={t('manageYearbook.chapterAdd')}
                size={'sm'}
                onPress={onAddModule}
              />
            }
          />
        </Section>
        <VSpacer />

        {moduleInstances.map((moduleInstance, index) => {
          return (
            <CommonIconCell
              key={moduleInstance.id}
              testID={`CommonIconCell:${moduleInstance.id}`}
              ref={(el) => (commonIconCellsRef.current[index] = el)}
              swipeable
              info={{
                text: moduleInstance.module.name || '',
                img: moduleInstance.module.image || ' ',
              }}
              onPress={() => onSelectModule(moduleInstance.module.id)}
              onDelete={() => {
                commonIconCellsRef?.current?.[index]?.collapse();
                onDeleteModuleInstance(moduleInstance.id);
              }}
            />
          );
        })}
        <VSpacer height={120} />
      </ScrollView>
    </TabScreenLayout>
  );
}
