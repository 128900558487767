import { NavigatorScreenParams } from '@react-navigation/native';
import { Event } from '~/data/models/calendar';
import { Group } from '~/data/operations/group/types/Group';
import { OnSetupGroupNextArgs } from '~/screens/Importer/SetupGroup';

/*
Implements the suggested pattern 'Protected Routes' from https://reactnavigation.org/docs/auth-flow/
The main idea is that the user just has access to some part of the screens, depending if it's logged or not
On gradoo app we have 3 main entry stacks

|-- AuthStack /auth (when not logged)
|-- LobbyStack /lobby (when logged but no selected group)
|-- MainStack /main (when logged and selected group)


All entry logic rellies on the context/auth state
So for example, the main default flow would be
t0 - AUTH   (authUserId: '', authGroupId: '') - user u1 login -> t1
t1 - LOBBY  (authUserId: 'u1', authGroupId: '') - user select group g1 -> t2
t2 - MAIN   (authUserId: 'u1', authGroupId: 'g1') - user select gorup g2 from modal -> t3
t3 - MAIN   (authUserId: 'u1', authGroupId: 'g2') - user logout -> t0
*/

type BaseAuthParams = {
  lang?: string;
};

export type ServiceParams = {
  layoutcreator?: string;
  cc?: string;
  shopReturnUrl?: string;
};

type InviteParams = {
  g?: string;
  t?: string;
  r?: string; // inviteCode for referral system
};

export type AuthStackParamList = {
  Launch: BaseAuthParams &
    ServiceParams & {
      animate?: boolean;
    };
  Login: ServiceParams & InviteParams;
  SignUpName: ServiceParams & InviteParams;
  SignUpContact: ServiceParams & InviteParams;
  SignUpPassword: ServiceParams & InviteParams;
  SignUpTerm: ServiceParams & InviteParams;
  ForgotPassword: {
    email: string;
  };
  ForgotPasswordInstruction: undefined;
  ResetPassword: {
    t: string;
  };
  ResetPasswordSuccess: undefined;
};

export type LobbyStackParamList = {
  Lobby: ServiceParams;
};

export type GroupStackParamList = {
  JoinSelectLocation: undefined;
  JoinSelectInstitute: {
    city: string;
  };
  JoinSelectGroup: {
    instituteId: string;
  };
  JoinGroupDetail: {
    groupId: string;
  };
  JoinGroupRequestSent: undefined;
  RequestInstitution: undefined;
  RequestInstitutionSent: undefined;
  CreateGroup: {
    instituteId: string;
  };
  CreateGroupSuccess: {
    groupId: string;
  };
};

export enum ImportStatuses {
  processing = 'processing',
  success = 'success',
  error = 'error',
}

export type ImporterStackParamList = {
  AbihomeLogin: undefined;
  SetupGroup: { name: string; userCount: number | undefined };
  Overview: { userCount: number | undefined } & OnSetupGroupNextArgs;
  ImportStatus: { status: ImportStatuses };
};

export type AdminStackParamList = {
  SearchStack: NavigatorScreenParams<SearchStackParamList>;
  InstitutesStack: NavigatorScreenParams<InstituteStackParamList>;
  AdsStack: NavigatorScreenParams<AdsStackParamList>;
  ReportingStack: NavigatorScreenParams<ReportingStackParamList>;
  SupportStack: NavigatorScreenParams<SupportStackParamList>;
  SettingsStack: NavigatorScreenParams<SettingsStackParamList>;
};

export type SearchStackParamList = {
  Search: undefined;
  EditUser: { id: string };
  EditGroup: { id: string };
  EditInstitute: { id: string };
};

export type InstituteStackParamList = {
  Institutes: undefined;
  InstituteRequest: { id: string };
  InstituteAdd: undefined;
};

export type AdsStackParamList = {
  Ads: { refresh?: boolean } | undefined;
  EditAd: { id: string };
  EditAsset: { id: string; campaignName: string; campaignId: string };
  AddCampaign: undefined;
};

export type ReportingStackParamList = {
  Reporting: undefined;
  WorkInProgress: undefined;
};

export type SupportStackParamList = {
  Support: undefined;
};

export type SettingsStackParamList = {
  Settings: ServiceParams;
};

//screens from Planning that are showed on top of tab bar (root stack) on mobile
//and inside the same PlanningStackParamList on web
export type MobileRootPlanningStackParamList = {
  CreatePoll:
    | {
        teamId?: string;
      }
    | undefined;
  EditPoll: {
    postId: string;
  };
  Comments: {
    postId: string;
  };
  CreateEvent: undefined;
  EditEvent: {
    eventId: string;
  };
  EventDetail: {
    eventId: string;
  };
  CreatePost: {
    teamId?: string;
    imgUris?: string[];
  };
  GroupSettings: {
    group: Group;
  };
  CreateCustomPage: {
    moduleInstanceId: string;
  };
  Calendar: undefined;
  AttendeesList: { event?: Event };
  AddTeamMember: {
    teamId?: string;
    localIncludedUserIds?: string[];
    onAddUser: (userId: string) => void;
  };
};

export type CommonPlanningStackParamList = {
  Teams: undefined;
  CreateTeam: undefined;
  TeamDetail: {
    teamId: string;
  };
  EditTeam: {
    teamId: string;
  };
  Polls: undefined;
  ToDoLists: undefined;
  Calendar: undefined;
  EditEvent: {
    eventId: string;
  };
  ToDoTasks: {
    listId: string;
  };
};

export type PlanningStackParamList = {
  Planning: undefined;
} & CommonPlanningStackParamList &
  MobileRootPlanningStackParamList;

export type FeedStackParamList = {
  Feed: undefined;
} & CommonPlanningStackParamList &
  MobileRootPlanningStackParamList;

//screens from Yearbook that are showed on top of tab bar (root stack) on mobile
//and inside the same YearbookStackParamList on web
export type MobileRootYearbookStackParamList = {
  ProfileCreateComment: {
    moduleInstanceId: string;
    profileUserGroupId: string;
    profilePageCommentId?: string;
    viewOnly?: boolean;
    currentText?: string;
  };
  ProfilePageAddQuestion: {
    currentText?: string;
    onAddedQuestion: (text: string) => void;
  };
  ProfilePageAddPhotoCategory: {
    currentText?: string;
    onAddedPhotoCategory: (text: string) => void;
  };
  CollagesPhotoDetail: {
    moduleInstanceId: string;
    collageAlbumId: string;
    collagePhotoId: string;
    viewOnly?: boolean;
  };
  CreateCollage: {
    moduleInstanceId: string;
    collageAlbumId?: string;
    currentText?: string;
  };
  ReportDetail: {
    moduleInstanceId: string;
    reportInstanceId?: string;
    viewOnly?: boolean;
  };
  ReportSettings: {
    moduleInstanceId: string;
    reportInstanceId: string;
  };
  AddQuote: {
    moduleInstanceId: string;
  };
  RankingsAddQuestion: {
    moduleInstanceId: string;
    rankingQuestionId?: string;
  };
  RankingsCustomList: {
    moduleInstanceId: string;
    customListId?: string;
    customListTitle?: string;
  };
  RankingsCustomListOption: {
    customListId: string;
  };
  RankingVote: {
    rankingQuestionId: string;
  };
  AddModule: undefined;
  ModuleDetail: {
    moduleId: string;
    viewOnly?: boolean;
  };
  ProfilePageAnswer: {
    questionId: string;
    questionTitle: string;
    maxChars: number;
    answerId?: string;
    currentText?: string;
    viewOnly?: boolean;
  };
  ExportData: undefined;
  Exporting: undefined;
  ExportHistory: undefined;
};

export type YearbookStackParamList = {
  Yearbook: undefined;
  ProfilePage: {
    moduleInstanceId: string;
    userGroupId?: string;
    viewOnly?: boolean;
  };
  ProfilePageComments: {
    maxChars?: number;
    currentText?: string;
    moduleInstanceId: string;
  };
  ProfilePageSetup: {
    moduleInstanceId: string;
  };
  Quotes: {
    moduleInstanceId: string;
    viewOnly?: boolean;
  };
  QuotesSetup: {
    moduleInstanceId: string;
  };
  Rankings: {
    moduleInstanceId: string;
    viewOnly?: boolean;
  };
  RankingsSetup: {
    moduleInstanceId: string;
  };
  Collages: {
    moduleInstanceId: string;
    viewOnly?: boolean;
  };
  CollagesAlbumDetail: {
    moduleInstanceId: string;
    collageAlbumId: string;
    viewOnly?: boolean;
  };
  CollagesSetup: {
    moduleInstanceId: string;
  };
  Reports: {
    moduleInstanceId: string;
    viewOnly?: boolean;
  };
  ReportsSetup: {
    moduleInstanceId: string;
  };
  Custom: {
    moduleInstanceId: string;
    viewOnly?: boolean;
  };
  CustomPageResults: {
    moduleInstanceId: string;
  };
  CustomPageResult: {
    moduleInstanceId: string;
    customPageId: string;
  };
  EditCustomPage: {
    moduleInstanceId: string;
    customPageId: string;
  };
  CustomSetup: {
    moduleInstanceId: string;
  };
  Manage: undefined;
  ManageRequest: undefined;
  CollectedData: undefined;
  BookStatistics: undefined;
  ProfilePageResults: {
    moduleInstanceId: string;
  };
} & MobileRootYearbookStackParamList;

export type ProfileStackParamList = {
  Profile: undefined;
  ProfileSetup: undefined;
  GroupSetup: undefined;
  GroupSettings: {
    group: Group;
  };
  NotificationSettings: undefined;
  AppSetup: undefined;
};

export type OnboardingType = 'core' | 'core_help';

export type MainTabParamList = {
  FeedStack: NavigatorScreenParams<FeedStackParamList>;
  PlanningStack: NavigatorScreenParams<PlanningStackParamList>;
  YearbookStack: NavigatorScreenParams<YearbookStackParamList>;
  ProfileStack: NavigatorScreenParams<ProfileStackParamList>;
};

export type RootEntranceParamList = {
  AuthStack: NavigatorScreenParams<AuthStackParamList>;
  LobbyStack: NavigatorScreenParams<LobbyStackParamList>;
  MainTab: { gid: string } & NavigatorScreenParams<MainTabParamList>;
  AdminStack: NavigatorScreenParams<AdminStackParamList>;
};

export type RootEntranceKey = keyof RootEntranceParamList;

export type BaseRootStackParamList = RootEntranceParamList & {
  GroupStack: NavigatorScreenParams<GroupStackParamList>;
  Browser: {
    title: string;
    url: string;
    onGoBack?: () => void;
  };
  Invite: InviteParams;
  InviteSuccess: {
    groupId: string;
  };
  SignUpSuccess: undefined;
  Onboarding: {
    onboardingType: OnboardingType;
  };
  Leaderboard: undefined;
  DeleteAccount: undefined;
  ImporterStack: NavigatorScreenParams<ImporterStackParamList>;
  NotFound: undefined;
  BrokenLink: undefined;
  ServerError: undefined;
};

//we declare the types for both web and mobile
//to be used in the screen navigation type (..NavProp = ...)
//so RootStackParamList always have MobileRoot... types (even on web)
//just like PlanningStackParamList always have MobileRootPlanningStackParamList (even on mobile)
//but the responsability add the screens to navigations remains on RootNavigation, PlanningStack and YearbookStack
//which handles to add or not the screens based on the Platform.OS
export type RootStackParamList = BaseRootStackParamList &
  MobileRootPlanningStackParamList &
  MobileRootYearbookStackParamList;
