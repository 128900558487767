import * as React from 'react';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { Share } from 'react-native';
import Button from '~/components/Button';
import { NotificationGroupRequestCarousel } from '~/components/NotificationCarousel';
import { useAuth } from '~/context/auth';
import { DRAWER_WIDTH } from '~/data/constants';
import useUserGroups from '~/data/hooks/useUserGroups';
import { useGroupQuery } from '~/data/operations/group/group';
import { getInviteUrl } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import { getRouteMeta } from '../utils/getRouteMeta';
import {
  DraweScrollView,
  DraweHeader,
  BrandImage,
  DraweContent,
  DrawerItem,
  DrawerIcon,
  DrawerLabel,
  DrawerFooter,
  ProfileContainer,
  ProfileAvatar,
  ProfileName,
  CardView,
  CardHeader,
  GroupImage,
  CardInfo,
  GroupName,
  GroupDetail,
} from './style';

const DRAWER_CONTENT_WIDTH = DRAWER_WIDTH - 2 * 24;
const DRAWER_BUTTON_WIDTH = DRAWER_CONTENT_WIDTH - 2 * 16;

interface CustomDrawerContentProps extends DrawerContentComponentProps {}

export default function CustomDrawerContent(
  props: CustomDrawerContentProps,
): JSX.Element {
  const { state, navigation } = props;

  const { authUserGroup, authGroupId } = useAuth();

  const { pendingUserGroups, onAnswerRequest } = useUserGroups();

  const { data: groupData } = useGroupQuery({
    skip: !authGroupId,
    variables: {
      id: authGroupId,
    },
  });

  const group = groupData?.group;
  const groupId = group?.id;
  const token = group?.token;
  const inviteUrl = groupId && token ? getInviteUrl(groupId, token) : '';

  const onManageGroup = () =>
    navigation.navigate('ProfileStack', {
      screen: 'GroupSetup',
      initial: false,
    });

  const onShareLink = () =>
    Share.share({
      url: inviteUrl,
    });

  const onProfile = () =>
    navigation.navigate('ProfileStack', { screen: 'Profile' });

  const onDeclineUser = (userId: string) => onAnswerRequest(userId, false);

  const onAcceptUser = (userId: string) => onAnswerRequest(userId, true);

  return (
    <DraweScrollView {...props}>
      <DraweHeader>
        <BrandImage
          source={require('~/assets/imgs/v2/brand_logo_1.png')}
          resizeMode={'contain'}
        />
      </DraweHeader>
      <DraweContent>
        {state.routes.map((route, index) => {
          const {
            isFocused: isActive,
            routeName,
            iconName,
            label,
          } = getRouteMeta(state, route, index);

          const onPress = () => navigation.navigate(routeName);

          return (
            <DrawerItem key={routeName} onPress={onPress} isActive={isActive}>
              <DrawerIcon isActive={isActive} name={iconName} />
              <DrawerLabel isActive={isActive}>{label}</DrawerLabel>
            </DrawerItem>
          );
        })}
      </DraweContent>
      <DrawerFooter>
        <CardView>
          <CardHeader>
            <GroupImage
              source={{
                uri: group?.avatar || group?.icon?.icon || undefined,
              }}
            />
            <CardInfo>
              <GroupName>{group?.name}</GroupName>
              <GroupDetail>{group?.institute.name}</GroupDetail>
            </CardInfo>
          </CardHeader>
          <Button
            testID={'buttonManageGroup'}
            text={t('profile.manageGroup')}
            size={'sm'}
            type={'secondary-base'}
            minWidth={DRAWER_BUTTON_WIDTH}
            bottomMargin={8}
            onPress={() => onManageGroup()}
          />
          <Button
            testID={'buttonShareLink'}
            text={t('profile.inviteNewMembers')}
            size={'sm'}
            type={'primary-brand-02'}
            minWidth={DRAWER_BUTTON_WIDTH}
            onPress={() => onShareLink()}
          />
        </CardView>
        {pendingUserGroups.length > 0 ? (
          <NotificationGroupRequestCarousel
            noPaginationMargin
            userGroups={pendingUserGroups}
            containerWidth={DRAWER_CONTENT_WIDTH}
            onDeclineUser={onDeclineUser}
            onAcceptUser={onAcceptUser}
          />
        ) : null}
        <ProfileContainer onPress={onProfile}>
          <ProfileAvatar uri={authUserGroup?.avatar} size={32} />
          <ProfileName>{authUserGroup?.user.firstName}</ProfileName>
        </ProfileContainer>
      </DrawerFooter>
    </DraweScrollView>
  );
}
