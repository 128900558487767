import React from 'react';
import Badge from '~/components/Badge';
import { BadgeColorTheme } from '~/components/Badge/types';
import Button from '~/components/Button';
import { FileDetails } from '~/components/ImagePicker';
import InputField from '~/components/InputField';
import PDFViewer from '~/components/PDFViewer';
import PhotoSizeCell from '~/components/PhotoSizeCells';
import { Spacer } from '~/components/common/style.web';
import { PageFormat, PageType } from '~/data/operations/global';
import { ModalScreenLayout } from '~/screens/style';
import { t } from '~/utils/i18n';
import { PHOTO_FORMAT_CELL_WIDTH } from '../../ProfilePageSetup/layout/style';
import {
  ScrollView,
  Section,
  ImageContent,
  ImageBanner,
  DeleteIcon,
  DeleteIconContainer,
  ImageContainer,
  FormatSection,
  PhotoFormatContainer,
  PhotoFormatDescription,
  ButtonCreate,
  ImageDescriptionContainer,
  ImageDescription,
  NoImageUploaded,
} from './style';

type CreateCustomPageLayoutProps = {
  onBack: () => void;
  onPickImage: () => void;
  imgUri?: FileDetails;
  selectedPhotoFormat: PageFormat;
  setSelectedPhotoFormat: React.Dispatch<React.SetStateAction<PageFormat>>;
  customPageTitle: string;
  setCustomPageTitle: React.Dispatch<React.SetStateAction<string>>;
  onCreateCustomPage: () => void;
  onRemoveImage: () => void;
};

const photoFormats: PageFormat[] = [
  PageFormat.PORTRAIT,
  PageFormat.LANDSCAPE,
  PageFormat.CUSTOM,
];

export const ImageQualityBadgeColor: { [key: string]: BadgeColorTheme } = {
  GOOD: 'green',
  CRITICAL: 'tertiary',
  POOR: 'red',
};

export default function CreateCustomPage({
  onBack,
  onPickImage,
  imgUri,
  selectedPhotoFormat,
  setSelectedPhotoFormat,
  customPageTitle,
  setCustomPageTitle,
  onCreateCustomPage,
  onRemoveImage,
}: CreateCustomPageLayoutProps): JSX.Element {
  const isImgUriPdf = imgUri?.type === PageType.PDF;
  return (
    <ModalScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.createCustomPage')}
      onBackPress={onBack}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <Section title={t('createCustomPage.titleSection')} />
        <InputField
          testID={'CustomPageTitle'}
          value={customPageTitle}
          onChangeText={(query) => setCustomPageTitle(query)}
          label={t('createCustomPage.titleInput')}
        />

        <ImageContainer>
          <ImageContent>
            {imgUri?.uri ? (
              isImgUriPdf ? (
                <PDFViewer
                  key={imgUri?.uri}
                  uri={imgUri?.uri}
                  hidePreview={true}
                  height={170}
                  width={121}
                />
              ) : (
                <ImageBanner
                  key={imgUri?.uri}
                  testID={`PagePhotoCategoryCardImage`}
                  source={{ uri: imgUri?.uri }}
                />
              )
            ) : (
              <NoImageUploaded />
            )}
          </ImageContent>
          {imgUri?.uri && (
            <DeleteIconContainer onPress={onRemoveImage}>
              <DeleteIcon />
            </DeleteIconContainer>
          )}
          <ImageDescriptionContainer>
            {imgUri?.uri && (
              <ImageDescription>
                {`${t('createCustomPage.imageType')} ${
                  imgUri?.type ? t(`createCustomPage.${imgUri.type}`) : ''
                }`}
              </ImageDescription>
            )}

            {imgUri?.uri && (
              <>
                <ImageDescription>{`${t('createCustomPage.imageSize')} ${
                  imgUri?.size ? imgUri?.size : ''
                }`}</ImageDescription>
                <ImageDescription>
                  {`${t('createCustomPage.imageQuality')}`}{' '}
                  {imgUri?.quality && (
                    <Badge
                      colorTheme={
                        ImageQualityBadgeColor[imgUri?.quality as string]
                      }
                      text={imgUri?.quality as string}
                    />
                  )}
                </ImageDescription>
              </>
            )}
          </ImageDescriptionContainer>
        </ImageContainer>
        <Button
          text={t('createCustomPage.uploadImage')}
          onPress={onPickImage}
          type="secondary-base"
          size="sm"
          flex
          minWidth={360}
        />
        <FormatSection title={t('createCustomPage.formatSection')} />
        <PhotoFormatContainer>
          {photoFormats.map((photoFormat) => (
            <PhotoSizeCell
              key={photoFormat}
              testID={`buttonFormat${photoFormat}`}
              format={photoFormat}
              selected={photoFormat === selectedPhotoFormat}
              width={PHOTO_FORMAT_CELL_WIDTH}
              onPress={() => setSelectedPhotoFormat(photoFormat)}
            />
          ))}
        </PhotoFormatContainer>
        <PhotoFormatDescription>
          {t('createCustomPage.formatDescription')}
        </PhotoFormatDescription>
        <Spacer v={183} />
        <ButtonCreate
          testID={'buttonCreate'}
          text={t('createCustomPage.createBtn')}
          onPress={onCreateCustomPage}
          size="lg"
          flex
          minWidth={360}
        />
        <Spacer v={20} />
      </ScrollView>
    </ModalScreenLayout>
  );
}
