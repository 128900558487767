import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import PostCell, { PostCellActionProps } from '~/components/PostCell';
import SectionHeader from '~/components/SectionHeader';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { AdsBiggerImage, AdsText, VSpacer } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { Post, PollOption } from '~/data/models/post';
import { VariantTypeEnum } from '~/data/operations/global';
import { getPollVotedOptions } from '~/data/utils';
import { TabScreenLayout } from '~/screens/style';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import {
  Header,
  Section,
  FeedList,
  Content,
  EmptyContent,
  EmptyText,
  Loading,
  RefreshControl,
  AdsTouch,
  EmptyImage,
  TrackingFlatlistWrapper,
} from './style';

export type PollsLayoutProps = {
  posts: Post[];
  loading: boolean;
  authUserId: string;
  authTeamIds: string[];
  pollsHasNextPage?: boolean;
  now?: Date;
  onBack: () => void;
  onCreatePoll: () => void;
  onHelp: () => void;
  onRefresh: () => void;
  onLoadMorePolls: () => void;
  openBannersInBrowser: (url: string) => void;
} & PostCellActionProps;

export default function Polls({
  posts,
  loading,
  authUserId,
  authTeamIds,
  pollsHasNextPage,
  now = new Date(),
  onBack,
  onPollVote,
  onCreatePoll,
  onLike,
  onComment,
  onDelete,
  onReportPost,
  onReportUser,
  onBlockUser,
  onHidePost,
  onRefresh,
  onLoadMorePolls,
  onEdit,
  openBannersInBrowser,
  onHelp,
}: PollsLayoutProps): JSX.Element {
  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.LARGE });

  const renderPostItem = ({
    item: post,
    index,
  }: {
    item?: Post;
    index: string;
  }) => {
    const pollVotedOptions = getPollVotedOptions(
      (post?.poll?.options as PollOption[]) || [],
      authUserId,
    );
    return (
      <PostCell
        testID={`${index}`}
        now={now}
        post={post}
        isPostOwner={authUserId === post?.createdBy?.user.id}
        pollVotedOptions={pollVotedOptions}
        authTeamIds={authTeamIds}
        onPollVote={onPollVote}
        onLike={onLike}
        onComment={onComment}
        onDelete={onDelete}
        onReportPost={onReportPost}
        onReportUser={onReportUser}
        onBlockUser={onBlockUser}
        onHidePost={onHidePost}
        onEdit={onEdit}
      />
    );
  };

  const renderAdsItem = ({
    item,
    index,
  }: {
    item?: BestMatchedAsset;
    index: number;
  }) => {
    const post = item as BestMatchedAsset;
    return (
      <AdsTouch
        testID={`${index}:AdsCell:${post?.id}`}
        onPress={() => {
          handleAdsClick(
            post?.id as string,
            post?.channelData?.[0]?.trackingUrl as string,
            openBannersInBrowser,
          );
        }}
      >
        <AdsText>{t('asset.gratitude')}</AdsText>
        <AdsBiggerImage
          source={{ uri: post?.channelData?.[0]?.imageLarge as string }}
          style={{ marginHorizontal: 16 }}
        />
      </AdsTouch>
    );
  };

  const isEmpty = !loading && posts.length === 0;

  const data = loading && posts.length == 0 ? [undefined, undefined] : posts;

  return (
    <TabScreenLayout
      bgColorKey="c1"
      backButtonTestID={'buttonGoBack'}
      title={t('g.polls')}
      onBackPress={onBack}
      RightComponent={
        <Button
          text={t('getHelp')}
          testID="getHelp"
          size={'sm'}
          type={'secondary-base'}
          onPress={onHelp}
        />
      }
    >
      {isEmpty ? (
        <EmptyContent>
          <EmptyImage source={general.ranking} />
          <EmptyText>{t('polls.pollsEmpty')}</EmptyText>
          <Button
            flex
            text={t('polls.firstPoll')}
            onPress={onCreatePoll}
            size={'xl'}
          />
        </EmptyContent>
      ) : (
        <FeedList<React.ElementType>
          testID={'pollsList'}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={onRefresh} />
          }
          ListHeaderComponent={
            !isEmpty && (
              <Header>
                {bestMatchedAsset?.[randomIndex] !== undefined && (
                  <TrackingFlatlistWrapper>
                    <TrackingFlatlist
                      testID={'flatList'}
                      handleViewTracking={handleViewTracking}
                      data={[bestMatchedAsset[randomIndex]]}
                      renderItem={renderAdsItem}
                      scrollEnabled={false}
                      ListFooterComponent={() => <></>}
                    />
                  </TrackingFlatlistWrapper>
                )}
                <VSpacer height={16} />
                <Section>
                  <SectionHeader
                    buttonTestID={'buttonCreatePoll'}
                    title={t('polls.pollsSection')}
                    onButtonPress={onCreatePoll}
                    RightComponent={
                      <Button
                        testID={'buttonCreatePoll'}
                        text={t('polls.newPoll')}
                        iconName={'plus'}
                        size={'sm'}
                        icon={'left'}
                        onPress={onCreatePoll}
                      />
                    }
                  />
                </Section>
              </Header>
            )
          }
          ListFooterComponent={
            loading &&
            posts.length > 0 && (
              <Content>
                <Loading />
              </Content>
            )
          }
          data={data}
          renderItem={renderPostItem}
          keyExtractor={(item: Post, i: number) => item?.id || i}
          onEndReachedThreshold={0.3}
          onEndReached={() => pollsHasNextPage && onLoadMorePolls()}
        />
      )}
    </TabScreenLayout>
  );
}
