import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { useCreateQuotesInstanceMutation } from '~/data/operations/yearbook/createQuotesInstance';
import {
  readQuotesInstancesQuery,
  writeQuotesInstancesQuery,
} from '~/data/operations/yearbook/helpers';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { useQuotesInstancesLazyQuery } from '~/data/operations/yearbook/quotesInstances';
import { QuotesInstancesVariables } from '~/data/operations/yearbook/types/QuotesInstances';
import { YearbookStackParamList } from '~/navigation/types';
import AddQuoteLayout from './layout';

// Note: The necessities for Updated Quotes UI
// Actions : Addtion, Deletion, Like, Unlike
// *** Newest Tab ***
// - Addtion: update cache
// - Deletion: update cache
// - Like: -
// - Unlike: -
// *** Most Liked Tab ***
// - Addtion: db request
// - Deletion: update cache
// - Like: db request cache
// - Unlike: db request cache

type AddQuoteNavProp = StackNavigationProp<YearbookStackParamList, 'AddQuote'>;

type AddQuoteRouteProp = RouteProp<YearbookStackParamList, 'AddQuote'>;

export default function AddQuote(): JSX.Element {
  const navigation = useNavigation<AddQuoteNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<AddQuoteRouteProp>();

  const { authUserGroupId } = useAuth();

  const { data: moduleInstanceData } = useModuleInstanceQuery({
    variables: {
      id: moduleInstanceId,
    },
  });

  const quotesSetupConfig = moduleInstanceData?.moduleInstance?.quotesSetup;

  const defaultVars = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
    authUserGroupId,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { refetch: refetchQuotesInstances }] = useQuotesInstancesLazyQuery({
    variables: defaultVars,
    notifyOnNetworkStatusChange: true,
  });

  const [createQuotesInstance, { loading: createQuoteLoading }] =
    useCreateQuotesInstanceMutation({
      update(cache, { data }) {
        const newQuotesInstance = data?.createQuotesInstance?.quotesInstance;

        const updateQuoteCache = (variables: QuotesInstancesVariables) => {
          const queryInstances = readQuotesInstancesQuery({
            cache,
            variables: variables,
          });

          if (
            queryInstances &&
            queryInstances.quotesInstances &&
            queryInstances.quotesInstances.edges &&
            newQuotesInstance
          ) {
            writeQuotesInstancesQuery({
              cache,
              variables: variables,
              data: {
                ...queryInstances,
                quotesInstances: {
                  ...queryInstances.quotesInstances,
                  edges: [
                    {
                      __typename: 'QuotesInstanceNodeEdge',
                      node: {
                        ...newQuotesInstance,
                        quotesInstanceLikes: null,
                      },
                    },
                    ...queryInstances.quotesInstances.edges,
                  ],
                },
              },
            });
          }
        };

        const newestVars = {
          ...defaultVars,
          orderBy: '-created',
        };

        updateQuoteCache(newestVars);
      },
      async onCompleted() {
        const mostLikedVars = {
          ...defaultVars,
          orderBy: '-likes',
        };
        await refetchQuotesInstances(mostLikedVars);
      },
    });

  const onSave = async (text: string) => {
    try {
      const { data } = await createQuotesInstance({
        variables: {
          input: {
            moduleInstance: moduleInstanceId,
            quote: text,
          },
        },
      });
      const messages = data?.createQuotesInstance?.errors?.map(
        (error) => error?.messages[0],
      );
      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
        return;
      }
      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <AddQuoteLayout
      maxCharCount={quotesSetupConfig?.maxChars}
      loading={createQuoteLoading}
      onSave={onSave}
      onBack={() => navigation.goBack()}
    />
  );
}
