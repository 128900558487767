import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { AdsImage, AdsText } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { ModuleInstanceResult } from '~/data/models/yearbook';
import { ModuleType, VariantTypeEnum } from '~/data/operations/global';
import { TabScreenLayout } from '~/screens/style';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import ModuleResultCell from './ModuleResultCell';
import { ModuleWrapper } from './style';

type CollectedDataLayoutProps = {
  moduleInstanceResults: ModuleInstanceResult[];
  loading: boolean;
  usersTotal: number;
  authUserId: string;
  onBack: () => void;
  onSelectModule: (moduleType: ModuleType, moduleInstanceId: string) => void;
  openBannersInBrowser: (url: string) => void;
};

export default function CollectedData({
  moduleInstanceResults,
  loading,
  usersTotal,
  authUserId,
  onBack,
  onSelectModule,
  openBannersInBrowser,
}: CollectedDataLayoutProps): JSX.Element {
  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.LARGE });

  const renderItem = ({
    item,
  }: {
    item?: BestMatchedAsset | ModuleInstanceResult;
  }) => {
    if (item?.__typename === 'MatchedAssetNode') {
      const post = item as BestMatchedAsset;
      return (
        <TouchableOpacity
          onPress={() => {
            handleAdsClick(
              post?.id as string,
              post?.channelData?.[0]?.trackingUrl as string,
              openBannersInBrowser,
            );
          }}
        >
          <AdsText>{t('asset.gratitude')}</AdsText>
          <AdsImage
            source={{ uri: post?.channelData?.[0]?.imageSmall as string }}
          />
        </TouchableOpacity>
      );
    }

    return (
      <ModuleWrapper>
        <ModuleResultCell
          moduleInstanceResult={item as ModuleInstanceResult}
          usersTotal={usersTotal}
          onSelectModule={onSelectModule}
        />
      </ModuleWrapper>
    );
  };

  const data =
    loading && moduleInstanceResults.length == 0
      ? Array(5).fill(undefined)
      : moduleInstanceResults;
  let combinedData = [...data];
  if (bestMatchedAsset) {
    const newElement =
      bestMatchedAsset !== null ? bestMatchedAsset[randomIndex] : null;

    if (newElement) {
      combinedData = [newElement, ...data];
    }
  }
  return (
    <TabScreenLayout
      backButtonTestID={'buttonBack'}
      title={t('screens.collectedData')}
      onBackPress={onBack}
    >
      <TrackingFlatlist
        testID="moduleResultList"
        data={combinedData}
        renderItem={renderItem}
        handleViewTracking={handleViewTracking}
      />
    </TabScreenLayout>
  );
}
